<template>
  <v-menu v-if="view === 'list'" :close-on-content-click="false" :offset-x="!isButton" :offset-y="isButton" :left="!isButton">
    <template v-slot:activator="{ on }">
      <v-btn v-if="isButton" v-on="on" text>
        Include Columns
        <v-icon right>fal fa-chevron-down</v-icon>
      </v-btn>
      <v-list-item v-else v-on="on">
        <v-list-item-avatar>
          <v-icon>fal fa-chevron-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Choose Included Columns</v-list-item-title>
      </v-list-item>
    </template>
    <v-list dense>
      <v-list-item v-for="({ text, subtext, field }) of columns" :key="'col-' + field" @click="toggle(field)">
        <v-list-item-avatar>
          <v-icon v-if="show[field]">fas fa-check-square</v-icon>
          <v-icon v-else>fal fa-square</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
          <v-list-item-subtitle>{{ subtext }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
export default {
  props: {
    isButton: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { root }) {
    const view = computed(() => root.$store.state.greatscots.listView)
    const show = ref({
      dep: true,
      phone: false,
      hall: false,
      room: false,
      box: true,
      job: true
    })
    const columns = computed(() => {
      return [
        { text: 'Department', subtext: 'Faculty/Staff Only', field: 'dep' },
        { text: 'Phone', subtext: 'Faculty/Staff Only', field: 'phone' },
        { text: 'Hall', subtext: 'Students Only', field: 'hall' },
        { text: 'Office/Room', subtext: 'Everyone', field: 'room' },
        { text: 'Box Number', subtext: 'Residential Students/Staff Only', field: 'box' },
        { text: 'Job Title', subtext: 'Faculty/Staff Only', field: 'job' }
      ]
    })

    onMounted(() => {
      if ('settings' in root.$store.state.user && 'directoryColumns' in root.$store.state.user.settings) {
        const dirCols = root.$store.state.user.settings.directoryColumns
        for (let l in dirCols) {
          show.value[l] = dirCols[l]
        }
      }
    })

    function toggle (field) {
      console.log(field, show.value[field], !show.value[field])
      show.value[field] = !show.value[field]
      root.$store.dispatch('updateUserSetting', { field: 'directoryColumns', value: show.value })
    }

    return {
      view,
      show,
      columns,
      toggle
    }
  }
}
</script>
